import {Dispatch} from "redux";
import jwt_decode from "jwt-decode";

import loginApi from "../apis/loginApi";
import {ActionTypes} from "./types";
import baseApi from "../apis/baseApi";


type AuthTokens = {
    access: string
    refresh: string
    error?: string
}

export type AuthState = {
    tokens: AuthTokens
    loggedIn: boolean
    authHeader: {}
    error: string
    loading: boolean
}


export interface LoginAction {
    type: ActionTypes
    payload: any
}

export const getTokensFromStorage = () => {
    return (dispatch: Dispatch) => {
        const access = localStorage.getItem("access")
        const refresh = localStorage.getItem("refresh")
        if (!access || !refresh) {
            dispatch<LoginAction>({
                type: ActionTypes.STORAGE_TOKEN_FAILED,
                payload: {access: "", refresh: ""} as AuthTokens
            })
        } else {
            // TODO :  Check token expiration first
            var token = jwt_decode(refresh)
            dispatch<LoginAction>({
                type: ActionTypes.STORAGE_TOKEN_SUCCESS,
                payload: {access: access, refresh: refresh} as AuthTokens
            })
        }
    }
}

export const login = (username: string, password: string) => {

    return async (dispatch: Dispatch) => {
        dispatch<LoginAction>({
                type: ActionTypes.LOGGING_IN,
                payload: null
            }
        )
        loginApi.post<AuthTokens>("/auth/jwt/create/", {username: username, password: password}).then((response) => {
            dispatch<LoginAction>({
                type: ActionTypes.LOGIN_SUCCESS,
                payload: response.data
            })
        }).catch((error) => {
                dispatch<LoginAction>({
                    type: ActionTypes.LOGIN_FAILED,
                    payload: error
                })
            }
        )
    }
}

export const superLogin = (studioId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch<LoginAction>({
                type: ActionTypes.LOGGING_IN,
                payload: null
            }
        )
        baseApi.post<AuthTokens>(`/staff/xlogin/?studio=${studioId}`).then((response) => {
            dispatch<LoginAction>({
                type: ActionTypes.LOGIN_SUCCESS,
                payload: response.data
            })
        }).catch((error) => {
                dispatch<LoginAction>({
                    type: ActionTypes.LOGIN_FAILED,
                    payload: error
                })
            }
        )
    }
}

export const logout = () => {
    return {
        type: ActionTypes.LOGOUT,
        payload: null
    }
}

export const logoutWithError = (error: string) => {
    return {
        type: ActionTypes.LOGOUT_WITH_ERROR,
        payload: error
    }
}