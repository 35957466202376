import React from "react";
import {useState} from 'react';
import {Formik, Form as FormikForm, Field} from "formik";
import FormField from "../../components/form/FormField";
import {Alert, Button} from "react-bootstrap";
import {getApiErrorMessage} from "../../utils/apiErrors";
import baseApi from "../../apis/baseApi";
import {FormikSelect} from "../../components/form/FormikSelect";
import {tzOptions} from "../../data/timezones";

const Registration = () => {
    const [success, setSuccess] = useState<boolean>();
    const [message, setMessage] = useState("");
    const [studioId, setStudioId] = useState("");
    const [branchId, setBranchId] = useState("");

    const renderAlert = () => {
        if (success === undefined) return;
        return <Alert
            variant={success ? "success" : "danger"}>{success ? "Studio creado" : `Error: ${message}`}</Alert>;
    };

    return (
        <div>
            {renderAlert()}
            <div className="card-body card" hidden={!studioId}>
                <h5>Iframes</h5>
                <div>My Studio</div>
                <a href={`https://mystudio.boostapp.com.mx/#/login/${studioId}}`}>https://mystudio.boostapp.com.mx/#/login/{studioId}</a>
                <div>Horarios</div>
                <a href={`https://boostapp.com.mx/schedules?studio=${studioId}&branch=${branchId}`}>https://boostapp.com.mx/schedules?studio={studioId}&branch={branchId}</a>
                <div>Planes</div>
                <a href={`https://boostapp.com.mx/?studio=${studioId}&branch=${branchId}`}>https://boostapp.com.mx/?studio={studioId}&branch={branchId}</a>
                <div>Coaches</div>
                <a href={`https://coaches.boostapp.com.mx/?studio=${studioId}`}>https://coaches.boostapp.com.mx/?studio={studioId}</a>
                <div>Links Website</div>
                <a href={`https://cyclingboost.link/${studioId.substring(30)}`}>https://cyclingboost.link/{studioId.substring(30)}</a>
            </div>
            <h4 className="offset-lg-1 mt-4">Registrar Estudio</h4>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    full_name: "",
                    phone: "",
                    is_manager: 1,
                    is_staff: 1,
                    studio_name: "",
                    timezone: "America/Mexico_City",
                    currency: "mxn"
                }}
                onSubmit={(values, {setSubmitting}) => {
                    baseApi
                        .post("/sa/", {
                            ...values,
                            username: values.email,
                            studio: {name: values.studio_name}
                        })
                        .then(resp => {
                            setSuccess(true)
                            setStudioId(resp.data.studio_id)
                            setBranchId(resp.data.branch_id)
                            setSubmitting(false)
                        }).catch(err => {
                            setMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        }
                    );
                }}
            >
                {({isSubmitting}) => (
                    <FormikForm>
                        <FormField label="E-Mail" name="email"/>
                        <FormField label="Password" name="password"/>
                        <FormField label="Nombre completo" name="full_name"/>
                        <FormField label="Teléfono" name="phone"/>
                        <FormField label="Nombre del Estudio" name="studio_name"/>
                        <div className="offset-lg-1 row-cols-lg-4 my-2">
                            <label>Divisa</label>
                            <Field name="currency" component={FormikSelect}
                                   options={[
                                       {value: "ars", label: "Argentina - ARS"},
                                       {value: "cad", label: "Canada - CAD"},
                                       {value: "cop", label: "Colombia - COP"},
                                       {value: "eur", label: "Euro - €"},
                                       {value: "hnl", label: "Honduras - HNL"},
                                       {value: "mxn", label: "México - MXN"},
                                       {value: "pen", label: "Perú - SOL"},
                                       {value: "usd", label: "United States - USD"},
                                   ]}/>
                        </div>
                        <div className="offset-lg-1 row-cols-lg-4 my-2">
                            <label>Timezone</label>
                            <Field name="timezone" component={FormikSelect} options={tzOptions}/>
                        </div>
                        <Button className="offset-lg-1 mt-5" type="submit" disabled={isSubmitting}>
                            Guardar
                        </Button>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

export default Registration;
