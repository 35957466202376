import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field} from "formik";
import {TimeSlot} from "../Schedules";
import {endOfDay, format, formatISO, parseISO} from "date-fns";
import yup from "../../utils/yup";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import FieldError from "../../components/form/FieldError";
import {useDispatch, useSelector} from "react-redux";
import {addReservation} from "../../actions/timeslotReservations";
import {Seat} from "../Toolbox/Rooms";
import {CartMember} from "../Pos/Cart";
import Select from "react-select";
import {StoreState} from "../../reducers";
import {Branch} from "../../actions";
import FormField from "../../components/form/FormField";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {MemberPlan} from "../Members/MemberDetails/MemberPlans";
import {formatDate} from "../../utils/dateFormatters";
import Checkbox from "../../components/form/Checkbox";

type Props = {
    show: boolean,
    onHide: Function,
    timeslot: TimeSlot
    onUpdateTimeSlot: (ts: TimeSlot) => void
}

const CreateRecurrentRsvpRule = ({show, onHide, timeslot, onUpdateTimeSlot}: Props) => {
    const dispatch = useDispatch()
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [selectedSeat, setSelectedSeat] = useState<Seat>()
    const [searchTerm, setSearchTerm] = useState("")
    const [fetchingMembers, setFetchingMembers] = useState(false)
    const [members, setMembers] = useState<CartMember[]>([])
    const [selectedMemberId, setSelectedMemberId] = useState<string>();
    const [fetchingMemberships, setFetchingMemberships] = useState(false);
    const [memberships, setMemberships] = useState<[MemberPlan]>();
    const [selectedMembership, setSelectedMembership] = useState<MemberPlan>();
    const [dateEnd, setDateEnd] = useState<Date>();

    useEffect(() => {
        baseApi.get(
            `/members/search/?branch=${branch.id}`,
        ).then((response) => {
            setMembers(response.data.results);
            setFetchingMembers(false);
        }).catch();
    }, [branch])

    useEffect(() => {
        if (selectedMemberId) {
            setFetchingMemberships(true);
            baseApi.get(
                `/memberships/active/?member=${selectedMemberId}`,
            ).then((response) => {
                setMemberships(response.data.results);
                setFetchingMemberships(false);
            }).catch();
        }
    }, [selectedMemberId]);


    useEffect(() => {
        const searchFn = setTimeout(() => {
            if (searchTerm.length < 2) return
            setFetchingMembers(true)
            baseApi.get(
                `/members/search/?search=${searchTerm}&branch=${branch.id}`,
            ).then((response) => {
                setMembers(response.data.results);
                setFetchingMembers(false);
            }).catch();
        }, 800);
        return () => clearTimeout(searchFn);
    }, [searchTerm, branch]);


    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const ValidationSchema = yup.object().shape({
        member: yup.string().required(),
        membership: yup.string().required(),
        interval: yup.number().required(),
        end: yup.date().required()
    })

    const renderRoom = () => {
        if (timeslot.room === null || !timeslot.room.uses_map) return <React.Fragment/>
        let slots = Array.from(Array(timeslot.room.cols * timeslot.room.rows).keys())
        let spotWidth = Math.floor(683 / timeslot.room!.cols)
        return <div className=" border p-3 text-center">
            {slots.map(index => {
                const row = Math.floor(index / timeslot.room!.cols)
                const col = index % timeslot.room!.cols
                const seat = timeslot.room!.seats.find(seat => seat.col === col && seat.row === row)
                if (!seat) {
                    return <div className={`d-inline-flex`} key={index} style={{width: spotWidth}}/>
                }
                const occupied = timeslot.occupied_seats.includes(seat.id)
                return <div className={`d-inline-flex justify-content-center `} key={index}
                            style={{width: spotWidth}}>
                    <div onClick={occupied ? () => {
                    } : () => setSelectedSeat(seat)}
                         style={{
                             width: spotWidth / 2,
                             height: spotWidth / 2,
                             backgroundColor: occupied ? "#dee2e6" : selectedSeat?.id === seat.id ? "#ade8f4" : "white"
                         }}
                         className="border rounded-circle seat-select d-flex align-items-center justify-content-center ">
                        <div className="">
                            {seat.label}
                        </div>

                    </div>
                </div>
            })}
        </div>
    }
    if (!timeslot) return <React.Fragment/>
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{
                        member: undefined,
                        interval: undefined,
                        studio_class: `${timeslot.start_time.substring(0, 5)} - ${timeslot.studio_class.name}`,
                        membership: undefined,
                        end: undefined,
                        weekdays: ['0', '1', '2', '3', '4', '5', '6'],
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log(values)
                        let weekdays = ""
                        if (values.interval === 0) {
                            weekdays = values.weekdays.join(",")
                        }
                        setSuccess(null)
                        if (timeslot.room !== null && timeslot!.room.uses_map && !selectedSeat) {
                            setSuccess(false)
                            setAlertMessage("Es necesario escoger un lugar")
                            setSubmitting(false)
                            return
                        }
                        const url = "/rsvp/recurrent/"
                        let data: any = {
                            interval: values.interval,
                            interval_count: 1,
                            member: selectedMemberId,
                            membership: selectedMembership?.id,
                            studio_class: timeslot.studio_class.id,
                            initial_slot: timeslot.id,
                            preferred_seat: selectedSeat?.id,
                            start: timeslot.start,
                            end: formatISO(endOfDay(dateEnd!)),
                            time: timeslot.start_time,
                            weekdays: weekdays,
                        }
                        baseApi.post(url, data).then(resp => {
                            const memberData = members.find(m => m.id === values.member)!
                            let member = {
                                external_id: memberData.external_id,
                                full_name: memberData.full_name,
                                phone: "",
                                shoe_size: "",
                            }
                            dispatch(addReservation({
                                ...resp.data,
                                seat: selectedSeat?.label || null,
                                member: member,
                                email: memberData?.email ?? ""
                            }))
                            setAlertMessage("Reservación creada")
                            setSuccess(true)
                            setSubmitting(false)
                        }).catch(err => {
                            setAlertMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })


                    }}>
                    {({isSubmitting, setFieldValue, values}) => (
                        <FormikForm>
                            <Modal.Header className="text-primary">Reservación Multiple</Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="text-muted font-size-sm offset-lg-1">* Se crearan multiples
                                    reservaciones para este usuario en base a los siguientes parámetros.
                                </div>
                                <div className="offset-lg-1 font-weight-bolder my-5">
                                    {timeslot.studio_class.name} - {format(timeslot.start, "dd/MMM/yy H:mm")}
                                </div>
                                <FieldError name="member"/>
                                <div className="offset-lg-1 w-50 mb-4">
                                    <Select placeholder="Cliente"
                                            options={members.map((m) => ({
                                                value: m.id,
                                                label: `${m.external_id} - ${m.full_name} ${m.last_name ?? ''}`
                                            }))}
                                            onInputChange={(text) => setSearchTerm(text)}
                                            noOptionsMessage={() => "Sin resultados"}
                                            isLoading={fetchingMembers} loadingMessage={() => "Cargando..."}
                                            onChange={(option) => {
                                                setFieldValue("member", option?.value);
                                                setFieldValue("membership", undefined)
                                                setSelectedMemberId(option!.value!)
                                                setSelectedMembership(undefined)
                                            }}/>
                                </div>
                                <FormField label="Clase" name="studio_class" disabled={true}/>
                                <div className="row my-4">
                                    <div className="offset-lg-1 col-lg-2 col-form-label">
                                        Intervalo
                                    </div>
                                    <div className="mb-2 col-lg-4">
                                        <Select placeholder="Intervalo"
                                                options={[
                                                    {value: 0, label: "Diario"},
                                                    {value: 1, label: "Semanal"},
                                                    {value: 2, label: "Mensual (cada 4 semanas)"},
                                                ]}
                                                onInputChange={(text) => setSearchTerm(text)}
                                                noOptionsMessage={() => "Sin resultados"}
                                                isLoading={fetchingMembers} loadingMessage={() => "Cargando..."}
                                                onChange={(option) => setFieldValue("interval", option?.value)}/>
                                    </div>
                                </div>
                                <FieldError name="interval"/>
                                {/* WEEKDAYS */}
                                <div hidden={values.interval !== 0} className="row">
                                    <div className="offset-lg-1 col-lg-2 col-form-label">
                                        Dias
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="d-inline-flex">
                                            <div className="d-flex align-items-center flex-column">
                                                <Field name="weekdays" as="string" value={0} component={Checkbox}/>
                                                <label>Lu</label>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column">
                                                <Field name="weekdays" as="string" value={1} component={Checkbox}/>
                                                <label>Ma</label>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column">
                                                <Field name="weekdays" value={2} component={Checkbox}/>
                                                <label>Mi</label>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column">
                                                <Field name="weekdays" value={3} component={Checkbox}/>
                                                <label>Ju</label>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column">
                                                <Field name="weekdays" value={4} component={Checkbox}/>
                                                <label>Vi</label>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column">
                                                <Field name="weekdays" value={5} component={Checkbox}/>
                                                <label>Sa</label>
                                            </div>
                                            <div
                                                className="d-flex justify-content-center align-items-center flex-column">
                                                <Field name="weekdays" value={6} component={Checkbox}/>
                                                <label>Do</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-4">
                                    <div className="offset-lg-1 col-lg-2 col-form-label">
                                        Membresía
                                    </div>
                                    <div className="mb-2 col-lg-4">
                                        <Select
                                            value={!selectedMembership ? undefined : {label: selectedMembership?.plan.name, value: selectedMembership?.id}}
                                            placeholder="Membresía"
                                            options={memberships?.map(membership => ({
                                                value: membership.id,
                                                label: membership.plan.name
                                            }))}
                                            noOptionsMessage={() => "Sin resultados"}
                                            isLoading={fetchingMemberships} loadingMessage={() => "Cargando..."}
                                            onChange={(option) => {
                                                setFieldValue("membership", option?.value);
                                                const membership = memberships?.find(m => m.id === option?.value)
                                                if (membership) setSelectedMembership(membership!)
                                            }}/>
                                    </div>
                                    {!selectedMembership ? <React.Fragment/> : <div className="col">
                                        <div>

                                            <small>Venc. {formatDate(selectedMembership!.expire_on)}</small>
                                        </div>
                                        <div hidden={selectedMembership.plan.no_limit_credits}>
                                            <small>Créditos disponibles: {selectedMembership.credits_available}</small>
                                        </div>
                                    </div>}
                                </div>
                                <FieldError name="membership"/>
                                <div className="d-flex flex-row offset-lg-1 my-4">
                                    <p className="mr-10 col-form-label">Repetir hasta</p>
                                    <div className="customDatePickerWidth " style={{width: "220px"}}>
                                        <ReactDatePicker
                                            dateFormat="dd/MMM/yyyy"
                                            locale={es}
                                            selected={dateEnd}
                                            minDate={timeslot.start}
                                            maxDate={!selectedMembership ? timeslot.start : parseISO(selectedMembership.expire_on)}
                                            onChange={date => {
                                                setFieldValue("end", date)
                                                setDateEnd(date as Date)
                                            }}
                                            customInput={<input className="form-control"/>}
                                        />
                                    </div>
                                </div>
                                <FieldError name="end"/>
                                {/*<div className="offset-lg-1 mt-4">Lugar preferido</div>*/}
                                {renderRoom()}
                                <div className="mt-4"/>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : `Crear`}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateRecurrentRsvpRule;
