import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faEye} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {format} from "date-fns"
import {es} from "date-fns/locale"

type SchedulesHeaderProps = {
    currentDate: Date
    onArrowBack: () => void
    onArrowForward: () => void
    setShowCreateModal: (show: boolean) => void
}

const SchedulesHeader = ({onArrowBack, onArrowForward, currentDate, setShowCreateModal} : SchedulesHeaderProps) => {

    return (
        <div className="mt-5 mb-8">
            <div className="align-items-center">


                {/*  */}
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <i className="fi-rr-calendar mr-3"></i>
                        <h2 className="text-dark">Calendario</h2>
                        <button onClick={() => setShowCreateModal(true)} className="d-none d-md-inline ml-5 btn btn-light-primary  btn-pill btn-lg bg-primary-o-50 text-primary border-0 btn-hover-primary font-weight-bold">+ Nuevo Horario</button>
                    </div>
                    <div>
                        {/* <Button className="btn-light-primary mr-3 d-none d-md-block">
                            <FontAwesomeIcon icon={faEye} />
                            {" "}Listado
                        </Button> */}
                        <Button className="btn-light btn-icon btn-circle d-md-none text-muted">
                            <FontAwesomeIcon icon={faEye} />
                            {" "}
                        </Button>
                        <button onClick={() => setShowCreateModal(true)} className="d-md-none btn btn-white text-primary shadow btn-circle btn-icon ml-2">
                            <i className="fi-rr-plus text-primary icon-md"></i>
                        </button>
                    </div>
                </div>





                {/*-------------------- AREA TITULO PARTE DERECHA BOTONES-----------------*/}
                {/*begin::Toolbar*/}
                <div className="d-flex align-items-center flex-wrap justify-content-between mt-10 mb-n5">

                    <Button onClick={onArrowBack} className="btn btn-sm btn-light btn-icon btn-circle bg-white text-dark shadow-sm mr-2"
                            data-toggle="tooltip" data-original-title="Semana anterior">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <h6 className="text-uppercase text-dark font-weight-bold mt-2 mr-5 align-middle ml-5 text-center">{format(currentDate, "MMMM yyyy", {locale: es})}</h6>

                    <Button onClick={onArrowForward} className="btn btn-sm btn-light btn-icon btn-circle bg-white text-dark shadow-sm"
                            data-toggle="tooltip" data-original-title="Siguiente semana">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </div>

                {/*end::Toolbar*/}
            </div>
            {/* END MOBILE HEADER */}


        </div>

    );
};

export default SchedulesHeader;
