import React from 'react';
import CheckInPill from "../../../pages/Reservations/CheckInPill";
import {Button} from "react-bootstrap";
import baseApi from "../../../apis/baseApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {WaitList} from "../../../pages/Reservations/WaitListTable";

type WaitListTrProps = {
    waitListItem: WaitList
}

const WaitListTr = ({waitListItem}: WaitListTrProps) => {
    return (
        <tr
            className={`font-size-sm align-middle text-center ${waitListItem.activated ? "bg-success" : waitListItem.cancelled ? "bg-secondary" : ""}`}
            key={waitListItem.id}>
            <td className=" text-uppercase"> {waitListItem.member.external_id}</td>

            <td className="align-middle text-left">
                <div className="font-weight-bold font-size-sm">{waitListItem.member.full_name}</div>
            </td>

            <td>{waitListItem.member.shoe_size}</td>
            <td>
                <Button
                    className="btn btn-icon btn-light btn-xs btn-hover-danger mr-1"
                    onClick={() => {
                        baseApi.delete(`/rsvp/waitlist/update/${waitListItem.id}/`).then((resp) => {
                        }).catch(() => alert("Error intentando cancelar"))
                    }}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </Button>
                <Button
                    className="btn btn-icon btn-light btn-xs btn-hover-primary">
                    <FontAwesomeIcon icon={faUser}/>
                </Button>
            </td>
        </tr>
    );
};

export default WaitListTr;
