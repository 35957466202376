import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {endOfDay, format, formatISO, parseISO, startOfDay, startOfMonth} from "date-fns";
import baseApi from "../../../apis/baseApi";
import {CSVLink} from "react-csv";
import {Button, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/free-solid-svg-icons";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";

interface CoachSlotPayment {
    id: string;
    coach_id: string;
    coach_full_name: string;
    class_name: string;
    start: string;
    assistance_count: number;
    reservations_count: number;
    reservations_cancelled_outside_limit: number;
    payment_type: number | null;
    rank: number
    total: number
}

interface CoachTotalPayment {
    coach_id: string;
    coach_full_name: string;
    assistance_count: number;
    reservations_count: number;
    reservations_cancelled_outside_limit: number;
    payment_type: number | null;
    total: number;
    classes_count: number;
}

const CoachCommissionsReport = () => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const [rangeStart, setRangeStart] = useState<Date>(startOfMonth(new Date()));
    const [rangeEnd, setRangeEnd] = useState<Date | null>(new Date());
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState<CoachSlotPayment[]>([]);
    const [coachPayments, setCoachPayments] = useState<CoachTotalPayment[]>([]);

    useEffect(() => {
        if (rangeStart && rangeEnd) {
            const fetchPage = () => {
                const start = formatISO(startOfDay(rangeStart))
                const end = formatISO(endOfDay(rangeEnd))
                setLoading(true)
                baseApi.get<CoachSlotPayment[]>(`/reports/coach-payments/?branch=${branch.id}&start=${start}&end=${end}`).then(resp => {
                    let payments: CoachTotalPayment[] = []
                    resp.data.forEach(sp => {
                        let index = payments.findIndex(p => p.coach_id === sp.coach_id)
                        if (index >= 0) {
                            payments[index].assistance_count += sp.assistance_count
                            payments[index].reservations_count += sp.reservations_count
                            payments[index].reservations_cancelled_outside_limit += sp.reservations_cancelled_outside_limit
                            payments[index].total += sp.total
                            payments[index].classes_count += 1
                        } else {
                            payments.push({
                                coach_id: sp.coach_id,
                                coach_full_name: sp.coach_full_name,
                                assistance_count: sp.assistance_count,
                                reservations_count: sp.reservations_count,
                                reservations_cancelled_outside_limit: sp.reservations_cancelled_outside_limit,
                                payment_type: sp.payment_type,
                                total: sp.total,
                                classes_count: 1,
                            })
                        }
                    })
                    resp.data.sort((a, b) => {
                        if (a.coach_full_name < b.coach_full_name) return -1
                        if (a.coach_full_name > b.coach_full_name) return 1
                        return 0
                    })
                    setItems(resp.data)
                    setCoachPayments(payments)
                    setLoading(false)
                }).catch(err => {
                    setLoading(false)
                },)
            }
            fetchPage()
        }
    }, [branch, rangeStart, rangeEnd]);

    const renderButton = () => {
        const headers = [
            {label: "Coach", key: "coach_full_name"},
            {label: "Clase", key: "class_name"},
            {label: "Fecha", key: "start"},
            {label: "Reservaciones", key: "reservations_count"},
            {label: "Asistencias", key: "assistance_count"},
            {label: "Canceladas sin devolución de crédito", key: "reservations_cancelled_outside_limit"},
            {label: "Rango", key: "rank"},
            {label: "Total", key: "total"},
        ]
        let filename = `Reporte comisiones ${branch.name} ${format(rangeStart, "dd/MM/yyyy")}`
        let data = items.map((p, index) => {
            let start = format(parseISO(p.start), "dd/MM/yyyy HH:mm")
            return {...p,   start}
        })
        return <CSVLink filename={filename} data={data} headers={headers} target="_blank">
            <Button variant="outline-success" className="my-3">
                <span><FontAwesomeIcon icon={faFileExcel}/> Descargar</span>
            </Button>
        </CSVLink>
    }


    return (
        <div>

            {/* TITULO */}
            <h1 className="pt-10">Reporte Comisiones Coaches</h1>
            <div className='mb-12 text-dark-50'>Descarga un reporte de las comisiones generadas por asistencias y reservaciones de los coaches.
            </div>

            <div className='font-size-h5'>Seleccionar fecha:</div>

            {/* FECHA */}
            <div className="d-sm-flex align-items-center">
                <div className='col-11 col-lg-9 col-xl-7 col-xxl-5 ml-n3'>
                    <ReactDatePicker
                        selectsRange
                        selected={rangeStart}
                        locale={es}
                        value={`${format(rangeStart, "dd MMMM yyyy", {locale: es})}  al  ${rangeEnd === null ? '' : format(rangeEnd, "dd MMMM yyyy", {locale: es})}`}
                        startDate={rangeStart}
                        endDate={rangeEnd}
                        shouldCloseOnSelect={rangeEnd === null}
                        maxDate={new Date()}
                        onChange={(dates) => {
                            if (dates) {
                                const [start, end] = dates as Date[]
                                setRangeStart(start)
                                setRangeEnd(end)
                            }
                        }}
                        customInput={<input className="form-control font-size-h4 text-primary"
                                            style={{cursor: "pointer"}}/>}
                    />
                </div>
                <div>{renderButton()}</div>

            </div>

            <div>
                <div className='mt-4'>
                    <div className='label label-l font-size-l p-4 font-weight-bold mr-2 label-inline'># reservaciones
                    </div>
                    <div
                        className='label label-light-success label-l font-size-l p-4 font-weight-bold mr-2 label-inline'>
                        # asistencias
                    </div>
                    <div
                        className='label label-light-warning label-l font-size-l p-4 font-weight-bold mr-2 label-inline'>
                        # cancelaciones sin devolución de crédito
                    </div>
                </div>
            </div>

            {/* TABLA */}
            <div className="rounded shadow-sm p-5 mb-5 mt-3 col-12 col-lg-10 col-xl-9 col-xxl-7"
                 style={{background: "white"}}>

                <Table responsive hover bordered>
                    <TableHeader
                        headers={["COACH", "CLASES IMPARTIDAS", "RESERVACIONES", "TIPO COMISIÓN", "TOTAL"]}/>
                    <tbody>
                    {loading ? <LoadingTr/> : coachPayments.map(item => {
                        return <tr className="" key={item.coach_id}>
                            <td className=" ">
                                <div className='font-size-h5 font-weight-bold'>{item.coach_full_name}</div>
                            </td>
                            <td className="text-center ">
                                <div className='font-size-h5 font-weight-bold'>{item.classes_count}</div>
                            </td>
                            <td className="text-left  font-size-lg">
                                <div className='text-center'>
                                    <div
                                        className='label label-lg font-size-h6 p-5 font-weight-bold mr-2'>{item.reservations_count}</div>
                                    <div
                                        className='label label-light-success label-lg font-size-h6 p-5 font-weight-bold mr-2'>{item.assistance_count}</div>
                                    <div
                                        className='label label-light-warning label-lg font-size-h6 p-5 font-weight-bold mr-2'>{item.reservations_cancelled_outside_limit}</div>
                                </div>
                            </td>
                            <td>{item.payment_type === 0 ? 'Asistencia' : item.payment_type === 1 ? 'Reservación' : ''}</td>
                            <td>{item.total}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
            {/* FIN TABLA */}

            <div className="py-20"/>
        </div>
    );
};

export default CoachCommissionsReport;
