import React from 'react';
import {MinCoach, TimeSlot} from "./index";
import {format, parse} from "date-fns";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisH, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from 'react-bootstrap';

type ScheduleCardProps = {
    timeSlot: TimeSlot,
    onDetails: () => void
    onEdit: () => void
    onDelete: () => void
}


const ScheduleCard = ({timeSlot, onDetails, onEdit, onDelete}: ScheduleCardProps) => {

    const renderCoachImage = (coaches: MinCoach[]) => {
        const coach = coaches.find(c => c.img !== null)
        if (coaches.length === 0 || coach === undefined) {
            return <FontAwesomeIcon className="text-muted" icon={faUserCircle} style={{fontSize: "45px"}}/>
        }
        return <img alt="coach" src={coach.img}/>
    }
   let start = timeSlot.start
    if (timeSlot.start_time !== null) {
        start =  parse(timeSlot.start_time, "HH:mm:ss", timeSlot.start)
    }
    return (
        <div className="card card-custom overlay mt-2 pt-2 text-center mb-4">
            <p className="font-weight-bolder">   {format(start, "h:mm aa")}</p>
            <div className="symbol symbol-40 symbol-lg-45 symbol-circle mx-auto mt-n3 pb-1">
                {renderCoachImage(timeSlot.coaches)}
            </div>
            <p className="text-muted font-size-xs m-0 p-0">{timeSlot.coaches.map(c => c.full_name).join(", ")}</p>
            <p>{timeSlot.studio_class.name}</p>
            <div className="overlay-layer align-items-start justify-content-between pt-1 pr-1">
                <button
                    className="btn btn-clean btn-icon btn-sm mr-2"
                    data-toggle="tooltip"
                    onClick={onEdit}
                    title=""
                    data-original-title="Editar Horario">
                    <FontAwesomeIcon icon={faEdit} size="lg"/>
                </button>
                <Dropdown>
                    <Dropdown.Toggle variant="link" bsPrefix="p-0">
                        <button className="btn btn-clean btn-icon btn-sm  p-0 m-0">
                            <FontAwesomeIcon icon={faEllipsisH}/>
                        </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={onDetails}>Ver Detalles</Dropdown.Item>
                        {/*<Dropdown.Item href="#/action-3">Reservaciones</Dropdown.Item>*/}
                        <Dropdown.Item onClick={onEdit}>Editar</Dropdown.Item>
                        <Dropdown.Item onClick={onDelete} className="text-danger" >Eliminar</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

            </div>
        </div>
    );
};

export default ScheduleCard;
