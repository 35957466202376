import React from 'react';
import {Sale, SaleItem, SaleItemType, SaleLocations} from "../../Reports/Sales";
import {Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import {formatDateTime} from "../../../utils/dateFormatters";

interface Props {
    sale: Sale
    onClose: () => void
}

const MemberSaleReceipt = ({sale, onClose}: Props) => {

    const getSaleItemTypeText = (saleItem : SaleItem) :string => {
        switch (saleItem.item_type) {
            case SaleItemType.fee:
                return "Multa"
            case SaleItemType.product:
                return "Producto"
            case SaleItemType.plan:
                return "Plan"
            case SaleItemType.registrationFee:
                return "Inscripción"
        }
    }

    return (
        <div className="p-5 mr-10">
            <div className="text-right">
                <button onClick={onClose} className="btn btn-danger btn-sm btn-circle">
                    <FontAwesomeIcon icon={faTimes}/>
                </button>
            </div>
            <h3 className="text-muted">#{sale.id.slice(-6).toUpperCase()}</h3>
            {sale.payment_method === null
                ? <div className="font-weight-bold font-size-lg">Pendiente de pago (deuda)</div>
                : <div
                    className="font-weight-bold font-size-lg">{sale.payment_method} {sale.location === SaleLocations.POS ?
                    <FontAwesomeIcon className="text-muted" icon={faCashRegister}/> : ""}
                    <div hidden={sale.paid_on?.substring(0, 10) === sale.created_on.substring(0, 10)}
                         className="font-weight-normal"><span className="text-success">*</span>Deuda
                        pagada el: {sale.paid_on ? formatDateTime(sale.paid_on) : ""}</div>
                </div>
            }
            <div>Atendió: {sale.transaction_by}</div>
            <div>{formatDateTime(sale.paid_on ?? sale.created_on)}</div>
            <Table hover>
                <tr>
                    <th>Cantidad</th>
                    <th>Artículo</th>
                    <th>Tipo</th>
                    <th className="text-right">Precio</th>
                </tr>
                <tbody>
                {sale.items.map((item) => <tr key={item.id} className="font-size-sm align-middle text-left">
                        <td>{item.quantity}</td>
                        <td>{item.name}</td>
                        <td>{getSaleItemTypeText(item)}</td>
                        <td className="text-right">{item.price}</td>
                    </tr>
                )}
                {sale.plan_items.map((item) => <tr key={item.id} className="font-size-sm align-middle text-left">
                        <td>{item.quantity}</td>
                        <td>{item.plan}</td>
                        <td>Plan</td>
                        <td className="text-right">{item.price}</td>
                    </tr>
                )}
                {sale.products.map((item) => <tr key={item.id} className="font-size-sm align-middle text-left">
                        <td>{item.quantity}</td>
                        <td>{item.product}</td>
                        <td>Producto</td>
                        <td className="text-right">{item.price}</td>
                    </tr>
                )}
                </tbody>
            </Table>
            <div className="text-right h6">
                Descuento: -${parseFloat(sale.discount ?? "0")}
            </div>
            <div className="text-right h3">
                Total: ${parseFloat(sale.total)}
            </div>
        </div>
    );
};

export default MemberSaleReceipt;
