import React, {useEffect, useRef, useState} from 'react';
import {Button,  Table} from "react-bootstrap";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {TimeSlot} from "../../Schedules";
import {format, parseISO, parse, isBefore} from "date-fns";
import {formatDateTime} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import DeleteModal from "../../../components/modals/DeleteModal";
import {es} from "date-fns/locale";
import {Member} from "../index";


interface Reservation {
    id: string
    assistance: boolean
    cancelled: boolean
    created_on: string
    cancelled_on?: string
    timeslot: TimeSlot
    seat: string
}

type UserRsvpProps = {
    member: Member
}

const MemberRsvp = ({member}: UserRsvpProps) => {
    const [reservations, setReservations] = useState<Reservation[]>([])
    const count = useRef(0)
    const [showDelete, setShowDelete] = useState(false);
    const [selectedRsvp, setSelectedRsvp] = useState<Reservation>();
    const cancelAllowed = useSelector<StoreState, boolean>(state => state.staffUser.permissions.find(p => p.feature === "reservations")?.delete ?? false)

    useEffect(() => {
        baseApi.get<Paginated>(`/rsvp/user/?member=${member.id}`).then((resp) => {
            count.current = resp.data.count
            setReservations(resp.data.results.map(r => {
                let start = parseISO(r.timeslot.start)
                if (r.timeslot.start_time !== null) {
                    start = parse(r.timeslot.start_time, "HH:mm:ss", start)
                }
                return ({
                    ...r,
                    timeslot: {...r.timeslot, start: start}
                });
            }))
        })
    }, [member])

    const handlePageChange = (page: number) => {
        baseApi.get<Paginated>(`/rsvp/user/?member=${member.id}&page=${page}`).then((resp) => {
            setReservations(resp.data.results.map(r => ({
                ...r,
                timeslot: {...r.timeslot, start: parseISO(r.timeslot.start)}
            })))
        }).catch((err) => alert("ERROR"))
    }

    const renderRow = (rsvp: Reservation) => {
        return <tr
            className={`font-size-lg  align-middle text-left ${rsvp.cancelled ? "bg-transparent" : ""}`}
            key={rsvp.id}>


            {/* RSVP ID */}
            {/* <td className=" text-uppercase"> {rsvp.id.slice(-6)}</td> */}
            {/* FIN RSVP ID */}

            {/* COL 1 */}
            <td>


                <div>
                    {format(rsvp.timeslot.start, "dd 'de' MMM 'de' yyyy")} <span
                    className='text-primary'>{format(rsvp.timeslot.start, "H:mm")}</span>
                </div>
                <div className='font-size-sm text-muted'>{rsvp.timeslot.studio_class.name}</div>
                <div className='mb-1'>
                    {rsvp.cancelled ?
                        <div className="font-size-">
                            <div
                                className='label label-inline label-light-danger font-weight-bold font-size-lg label-lg'>
                                <span>Cancelada el: </span>
                                <span className="text-muted ml-1">
                                {/* <FontAwesomeIcon icon={faCalendarTimes} className=" font-size-sm ml-2 mr-1 text-danger"/> */}
                                    <span className='text-danger'><u>{formatDateTime(rsvp.cancelled_on)}</u></span>
                            </span>
                            </div>
                        </div> : rsvp.assistance ? "" : ""}

                </div>
            </td>

            {/* COL 2 */}
            <td>
                <div className='text-center'>{rsvp.seat}</div>
            </td>

            {/* COL3 */}
            <td className='text-center'>
                {rsvp.cancelled ?
                    <div className="font-size-xs">
                        <div className='align-items-center mt-5'>
                            <span
                                className='label label-inline label-light-danger font-weight-bolder font-size'>CANCELADA</span>
                            <div className="text-muted">
                                {/* <FontAwesomeIcon icon={faClock} className=" font-size-sm mr-1 text-muted"/> */}
                                <span className='text-muted font-size-sm'>{formatDateTime(rsvp.cancelled_on)}</span>
                            </div>
                        </div>
                    </div> : rsvp.assistance ? <span
                            className='label label-inline label-light-success font-weight-bolder mt-3'>ASISTIÓ</span> :
                        <span className='label label-inline text-muted font-weight-bolder font-size-lg mt-3'>SIN ASISTENCIA</span>}
            </td>
            <td>
                {isBefore(new Date(), rsvp.timeslot.start) && !rsvp.cancelled ?
                    <div>
                        <Button
                            disabled={!cancelAllowed}
                            className="btn btn-icon btn-light btn-xs btn-hover-danger mr-1"
                            onClick={() => {
                                setSelectedRsvp(rsvp)
                                setShowDelete(true)
                            }}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </Button>
                    </div> : <React.Fragment/>
                }
                {/*<Button*/}
                {/*    className="btn btn-icon btn-light btn-xs btn-hover-primary">*/}
                {/*    <FontAwesomeIcon icon={faUser}/>*/}
                {/*</Button>*/}
            </td>


            {/* ORIGINAL */}
            {/* <td>{rsvp.timeslot.studio_class.name}</td>
                    <td>{format(rsvp.timeslot.start, "dd-MMM-yyyy H:mm")}</td>

                    <td>{rsvp.seat}</td>
                    <td className=" px-0">
                        {rsvp.cancelled ? <div className="font-size-xs">
                            CANCELADA
                            <div className="text-muted">
                                {formatDateTime(rsvp.cancelled_on)}
                            </div>
                        </div> : rsvp.assistance ? "SI" : "NO"}
                    </td> */}
            {/* FIN ORIGINAL */}
        </tr>
    }

    return (
        <div>
            <DeleteModal
                url={`/rsvp/update/${selectedRsvp?.id}/`}
                onSuccess={() => {
                    setReservations(
                        reservations.map(r => r.id === selectedRsvp!.id ? {
                            ...selectedRsvp!,
                            cancelled: true,
                            cancelled_on: new Date().toISOString()
                        } : r)
                    )
                }}
                show={showDelete}
                onHide={() => {
                    setShowDelete(false);
                    setSelectedRsvp(undefined)
                }}
                text={"Cancelar Reservación"}
                successText={"Reservación cancelada correctamente."}
            >
                {!selectedRsvp ? <React.Fragment/> :
                    <div>
                        <p className="p-0 m-0">Cliente: {member.full_name} {member.last_name ?? ''} ({member.external_id})</p>
                        <p className="p-0 m-0">Clase: {selectedRsvp!.timeslot.studio_class.name}</p>
                        <p className="p-0 m-0">Horario: {format(selectedRsvp!.timeslot.start, "dd/MMM/yyyy h:mm a", {locale: es})}</p>
                    </div>
                }
            </DeleteModal>
            <Table hover>
                <TableHeader headers={["FECHA", "SPOT", "CHECK-IN"]}/>
                <tbody>
                {reservations.length === 0 ? <EmptyTr/> : reservations.map((rsvp) => renderRow(rsvp))}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={reservations.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberRsvp;
