import React, {useEffect, useRef, useState} from 'react';
import {Member} from "../index";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import {Button, Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import TableFooter from "../../../components/table/TableFooter";
import {Sale, SaleItem} from "../../Reports/Sales";
import {formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faReceipt} from "@fortawesome/free-solid-svg-icons";
import MemberSaleReceipt from "./MemberSaleReceipt";

interface Props {
    member: Member,
}

const MemberSalesHistory = ({member} : Props) => {

    const [sales, setSales] = useState<Sale[]>([]);
    const [selectedSale, setSelectedSale] = useState<Sale>();
    const count = useRef(0)

    const handlePageChange = (page: number) => {
        baseApi.get<Paginated>(`sales/?member=${member.id}&page=${page}&page_size=10`).then(resp => {
            setSales(resp.data.results)
        }).catch()
    }

    useEffect(() => {
        baseApi.get<Paginated>(`sales/?member=${member.id}&page=1&page_size=10`).then(resp => {
            count.current = resp.data.count
            setSales(resp.data.results)
        }).catch()
    }, [member]);

    if (selectedSale) {
        return <MemberSaleReceipt sale={selectedSale} onClose={() => {
            setSelectedSale(undefined)
        }} />
    }
    return (
        <div>
            <Table hover>
                <TableHeader headers={["#", "FECHA", "IMPORTE", "MÉTODO", "ARTÍCULOS", ""]}/>
                <tbody>
                    {sales.length === 0 ? <EmptyTr/> : sales.map((sale) => <tr key={sale.id} className="font-size-sm align-middle text-center">

                        <td>{sale.id.slice(-6)}</td>
                        <td>{formatDateTime(sale.paid_on ?? sale.created_on)}</td>
                        <td>{sale.total}</td>
                        <td>{!sale.payment_method ? 'Pendiente de pago (deuda)' : sale.payment_method}</td>
                        <td>{sale.plan_items.length + sale.products.length + sale.items.length}</td>
                        <td>
                            <Button variant="link" size="sm" onClick={() => {
                                setSelectedSale(sale)
                            }}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </td>
                    </tr>)}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={sales.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberSalesHistory;
