import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {removePlan, StudioPlan, StudioPlansState} from "../../../actions/studioPlans";
import DeleteModal from "../../../components/modals/DeleteModal";
import CreateEditPlanModal from "./CreateEditPlanModal";
import CreatePromotionModal from "./CreatePromotionModal";
import {Badge, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faTag, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import RegistrationFees from "../RegistrationFees";

const PlansGridView = () => {
    const dispatch = useDispatch()
    const plansState = useSelector<StoreState, StudioPlansState>(state => state.studioPlans)
    const [showModal, setShowModal] = useState(false)
    const [showCreatePromotionModal, setShowCreatePromotionModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [noLimitCredits, setNoLimitCredits] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState<StudioPlan>()

    const getDaysDurationText = (plan: StudioPlan) => {
        let text = `${plan.interval_count} `
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return <span>{text.toUpperCase()}</span>
    }

    const getIntervalTypeText = (plan: StudioPlan) => {
        let text = ""
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return text.toUpperCase()
    }

    if (plansState.loading) return <div>Cargando...</div>
    return (
        <div style={{overflowY: "auto"}} className="p-4">
            <DeleteModal url={`/plans/${selectedPlan?.id}/`} onSuccess={() => {
                dispatch(removePlan(selectedPlan?.id || ""))
            }} show={showDeleteModal} onHide={() => {
                setShowDeleteModal(false)
                setSelectedPlan(undefined)
            }}>
                <p>¿Seguro que quieres eliminar el plan <strong> {selectedPlan?.name} </strong>?</p>
            </DeleteModal>
            <CreateEditPlanModal show={showModal} plan={selectedPlan} onHide={() => {
                setSelectedPlan(undefined)
                setShowModal(false)
            }} noLimitCredits={noLimitCredits}/>
            <CreatePromotionModal studioPlan={selectedPlan} show={showCreatePromotionModal} onHide={() => {
                setShowCreatePromotionModal(false)
                setSelectedPlan(undefined)
            }}/>
            {/*<ToolboxHeader title="Paquetes y Membresías"/>*/}
            <div className="mt-5">
                {/* PAQUETES DE CREDITOS */}
                <div className="d-flex align-items-center mb-5">
                    <h5 className="pt-2">Paquetes de Créditos</h5>
                    <button onClick={() => {
                        setNoLimitCredits(false)
                        setShowModal(true)
                    }
                    } className="btn btn-light-primary btn-pill ml-3">
                        + Nuevo Paquete
                    </button>
                </div>
                <div className="row border-bottom pb-5">
                    <div hidden={plansState.plans.find(p => !p.no_limit_credits) !== undefined}
                         className="text-center text-muted font-size-h2 w-100 mt-5">
                        0 paquetes de créditos registrados
                    </div>
                    {plansState.plans.filter((p) => !p.no_limit_credits).map((p) => {
                        return (
                            <div key={p.id} className="col-6 col-md-3">
                                <div className="card card-custom shadow text-center pb-5 pt-2 mt-2 px-2">

                                    <div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <div>

                                                <Button
                                                    hidden={p.is_manual_credits}
                                                    onClick={() => {
                                                        setSelectedPlan(p)
                                                        setShowDeleteModal(true)
                                                    }}
                                                    className="btn btn-icon btn-light-secondary btn-hover-danger bg-white btn-xs">
                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                </Button>

                                            </div>
                                            <div>
                                                <Button
                                                    hidden={p.is_subscription || p.is_manual_credits}
                                                    onClick={() => {
                                                        setSelectedPlan(p)
                                                        setShowCreatePromotionModal(true)
                                                    }}
                                                    className="btn btn-icon btn-light-secondary btn-hover-success  btn-xs bg-white mr-3">
                                                    <FontAwesomeIcon icon={faTag} rotation={90}/>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setSelectedPlan(p)
                                                        setNoLimitCredits(false)
                                                        setShowModal(true)
                                                    }}
                                                    className="btn btn-icon btn-light-secondary btn-xs bg-white">
                                                    <FontAwesomeIcon icon={faPen}/>
                                                </Button>
                                            </div>
                                        </div>
                                        <p className="text-muted font-weight-light mt-n5">{p.name}</p>
                                        <p className="h1">{p.credits}</p>
                                        <p className="font-weight-bolder mt-n2">{p.credits === 1 ? "CRÉDITO" : "CRÉDITOS"}</p>
                                        <p className="text-muted font-weight-light">{getDaysDurationText(p)}</p>
                                        {p.is_subscription ?<Badge className="mb-1" pill variant="success">Suscripción</Badge> : <React.Fragment />}
                                        <p className="text-primary h6" style={{letterSpacing: ".1rem"}}>${p.price}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* MEMBERESIAS */}
                <div className="d-flex align-items-center mt-5">
                    <h5 className="pt-2">Membresías</h5>
                    <button onClick={() => {
                        setNoLimitCredits(true)
                        setShowModal(true)

                    }
                    } className="btn btn-light-primary btn-pill ml-3">+ Nueva
                        Membresía
                    </button>
                </div>
                <div className="row border-bottom pb-5">
                    <div hidden={plansState.plans.find(p => p.no_limit_credits) !== undefined}
                         className="text-center text-muted font-size-h2 w-100 mt-5">
                        0 membresías registradas
                    </div>
                    {plansState.plans.filter((p) => p.no_limit_credits).map((p) => {
                        return (
                            <div key={p.id} className="col-6 col-md-3">
                                <div className="card card-custom shadow text-center pb-5 mt-2 pt-2 px-2">

                                    <div>
                                        <div className="d-flex justify-content-between mb-2">
                                            <Button
                                                onClick={() => {
                                                    setSelectedPlan(p)
                                                    setShowDeleteModal(true)
                                                }}
                                                className="btn btn-icon btn-light-secondary btn-hover-danger bg-white btn-xs">
                                                <FontAwesomeIcon icon={faTrashAlt}/>
                                            </Button>
                                            <div>
                                                <Button
                                                    hidden={p.is_subscription}
                                                    onClick={() => {
                                                        setSelectedPlan(p)
                                                        setShowCreatePromotionModal(true)
                                                    }}
                                                    className="btn btn-icon btn-light-secondary btn-hover-success  btn-xs bg-white mr-3">
                                                    <FontAwesomeIcon icon={faTag} rotation={90}/>
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setSelectedPlan(p)
                                                        setNoLimitCredits(true)
                                                        setShowModal(true)
                                                    }}
                                                    className="btn btn-icon btn-light-secondary btn-xs bg-white">
                                                    <FontAwesomeIcon icon={faPen}/>
                                                </Button>
                                            </div>
                                        </div>
                                        <p className="text-muted font-weight-light mt-n5">{p.name}</p>
                                        <p className="h1">{p.interval_count}</p>
                                        <p className="font-weight-bolder mt-n2">{getIntervalTypeText(p)}</p>
                                        {/*<p className="text-muted font-weight-light">{p.days_duration} dias</p>*/}
                                        {p.is_subscription ?<Badge className="mb-1" pill variant="success">Suscripción</Badge> : <React.Fragment />}
                                        <p className="text-primary h6" style={{letterSpacing: ".1rem"}}>${p.price}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* INSCRIPCIONES */}
                <RegistrationFees />
            </div>
        </div>
    );
};

export default PlansGridView;
