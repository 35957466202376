import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import TableHeader from "../../../components/table/TableHeader";
import LoadingTr from "../../../components/table/rows/LoadingTr";
import EmptyTrSales from "../../../components/table/rows/EmptyTrSales";
import {formatDateTime} from "../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCashRegister} from "@fortawesome/free-solid-svg-icons";
import TableFooter from "../../../components/table/TableFooter";
import baseApi from "../../../apis/baseApi";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import {endOfDay, formatISO, startOfDay} from "date-fns";
import {Paginated} from "../../../types";
import {Sale, SaleLocations} from "./index";
import {formatCurrency} from "../../../utils/currencyFormatters";

interface Props {
    rangeStart: Date
    rangeEnd: Date
}

const PlanSales = ({rangeStart, rangeEnd}: Props) => {
    const branch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const currency = useSelector<StoreState, string>(state => state.studio.preferences.currency)
    const [saleReports, setSaleReports] = useState<Sale[]>([])
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0)


    useEffect(() => {
        if (rangeStart && rangeEnd) {
            setLoading(true)
            const start = formatISO(startOfDay(rangeStart))
            const end = formatISO(endOfDay(rangeEnd))
            baseApi.get<Paginated>(`/sales/?branch=${branch.id}&start=${start}&end=${end}&plans=1`).then((resp) => {
                setSaleReports(resp.data.results)
                setCount(resp.data.count)
                setLoading(false)
            }).catch((err) => {
                alert("ERROR")
            })
        }
    }, [branch, rangeStart, rangeEnd]);


    const handlePageChange = (page: number) => {
        setLoading(true)
        const start = formatISO(startOfDay(rangeStart))
        const end = formatISO(endOfDay(rangeEnd))
        baseApi.get<Paginated>(`/sales/?branch=${branch.id}&page=${page}&start=${start}&end=${end}&plans=1`).then((resp) => {
            setSaleReports(resp.data.results)
            setCount(resp.data.count)
            setLoading(false)
        }).catch((err) => {
            alert("ERROR")
        })
    }

    return (
        <div>

            <Table hover>
                <TableHeader
                    headers={["ID", "FECHA", "MONTO", "USUARIO", "MÉTODO", "ATENDIÓ", "PLAN"]}/>
                <tbody>
                {loading ? <LoadingTr/> : saleReports.length === 0 ? <EmptyTrSales/> : saleReports.map(s => {
                    return <tr className="text-center" key={s.id}>
                        <td className="text-uppercase font-size-sm">{s.id.slice(-8)}</td>
                        <td>{formatDateTime(s.created_on)}</td>
                        <td>{formatCurrency(parseFloat(s.total), currency)}</td>
                        <td className="text-left">
                            <div className="font-weight-bold font-size-sm">{s.member.full_name}</div>
                            <div className="text-muted font-size-xs">{s.member.email}</div>
                        </td>
                        {s.payment_method === null
                            ? <td className="text-warning">Pendiente</td>
                            : <td>{s.payment_method} {s.location === SaleLocations.POS ?
                                <FontAwesomeIcon className="text-muted" icon={faCashRegister}/> : ""}
                                <div hidden={s.paid_on?.substring(0, 15) === s.created_on.substring(0, 15)}
                                     className="text-success font-size-xs">Pagado
                                    el: {s.paid_on ? formatDateTime(s.paid_on) : ""}</div>
                            </td>
                        }
                        <td>{s.transaction_by}</td>
                        <td className="text-center">{s.plan_items.map(i => (
                                <span>{i.plan}
                                    <span hidden={!i.cancelled} className="text-warning">
                                        :CANCELADO
                                    </span>,
                                </span>
                            )
                        )}
                        </td>
                    </tr>
                })}
                </tbody>

            </Table>
            <TableFooter count={count} pageCount={saleReports.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default PlanSales;
