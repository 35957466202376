import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm} from "formik";
import baseApi from "../../../../apis/baseApi";
import {addDays, isBefore, parseISO, startOfDay, differenceInDays, format} from "date-fns";
import {getApiErrorMessage} from "../../../../utils/apiErrors";
import yup from '../../../../utils/yup';
import FieldError from "../../../../components/form/FieldError";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {MemberPlan, MembershipPauseSchedule} from "../MemberPlans";
import {formatDate} from "../../../../utils/dateFormatters";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

type Props = {
    show: boolean,
    onHide: Function,
    memberPlans: MemberPlan[]
    setMemberPlans: (plans: MemberPlan[]) => void
    membership: MemberPlan
}

const PauseMembershipModal = ({show, onHide, memberPlans, setMemberPlans, membership}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const [activePauseSchedule, setActivePauseSchedule] = useState<MembershipPauseSchedule>();
    const [pauseEnd, setPauseEnd] = useState(startOfDay(addDays(new Date(), 1)));

    useEffect(() => {
        if (membership) {
            const pauseSchedule = membership.pause_schedules.find(ps => {
                const end = parseISO(ps.pause_end)
                return ps.status === "active" && isBefore(new Date(), end);
            })
            setActivePauseSchedule(pauseSchedule)
        }
    }, [membership]);


    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const renderPauseResultMessage = (pauseStart: Date) => {
        const diffInDays = differenceInDays(pauseEnd, pauseStart)
        const newMembershipExpiration = addDays(parseISO(membership.expire_on), diffInDays)
        return <div className="mt-3">
            La membresía sera pausada por <strong> {diffInDays} {diffInDays === 1 ? "día" : "días"} </strong>, el nuevo
            vencimiento de la membresía será:
            <div className="font-weight-bold font-size-h4">{format(newMembershipExpiration, "PPPP", {locale: es})}</div>
        </div>
    }

    const ValidationSchema = yup.object().shape({
        pause_start: yup.date().required(),
        pause_end: yup.date().required().when('pause_start', (pause_start: Date) => {
            if (pause_start) {
                return yup.date().min(addDays(pause_start, 1), "La fecha final debe ser mayor a la fecha de inicio.")
            }
            return yup.date()
        })
    })

    if (!membership) return  <React.Fragment />
    if (activePauseSchedule) {
        return <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                    Pausa de membresía
                </Modal.Header>
                <Modal.Body>
                    {renderAlert()}
                    <div className="pb-3 font-size-h4">
                        <div>Inicio: <strong>{formatDate(activePauseSchedule.pause_start)}</strong></div>
                        <div>Fin: <strong>{formatDate(activePauseSchedule.pause_end)}</strong></div>
                        <div
                            className="font-size-sm mt-2">Duración: {differenceInDays(parseISO(activePauseSchedule.pause_end), parseISO(activePauseSchedule.pause_start))} días.
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => {
                            baseApi.patch(`/memberships/pause/${activePauseSchedule?.id}/`, {"status": "cancelled"}).then(resp => {
                                setAlertMessage("Pausa de membresía cancelada")
                                setSuccess(true)
                                setActivePauseSchedule(undefined)
                                setMemberPlans(memberPlans.map(m => {
                                    if (m.id === membership.id) {
                                        m.expire_on = resp.data.membership_expire_on
                                        m.pause_schedules = []
                                        return m
                                    }
                                    return m
                                }))
                            }).catch(resp => {
                                setAlertMessage(getApiErrorMessage(resp))
                                setSuccess(false)
                            })
                        }} variant="outline-danger" size="sm">Cancelar pausa</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    }
    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={ValidationSchema}
                    initialValues={{pause_start: startOfDay(new Date()), pause_end: pauseEnd}}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        baseApi.post("/memberships/pause/", {
                            pause_start: values.pause_start,
                            pause_end: values.pause_end,
                            status: "active",
                            membership: membership.id
                        }).then(resp => {
                            console.log(resp)
                            setAlertMessage("Pausa creada")
                            setSuccess(true)
                            setMemberPlans(memberPlans.map(m => {
                                if (m.id === membership.id) {
                                    console.log(m.pause_schedules)
                                    m.expire_on = resp.data.membership_expire_on
                                    m.pause_schedules = [resp.data as MembershipPauseSchedule]
                                    console.log(m.pause_schedules)
                                    return m
                                }
                                return m
                            }))
                        }).catch(err => {
                            const msg = getApiErrorMessage(err)
                            setAlertMessage(msg)
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                Pausar membresía
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <div className="d-inline-flex align-items-start justify-content-between ">
                                    <div className="">
                                        <p className="m-0">Pausar a partir de:</p>
                                        <div className="customDatePickerWidth" style={{width: "220px"}}>
                                            <ReactDatePicker
                                                dateFormat="d 'de' MMMM 'del' yyyy"
                                                minDate={new Date()}
                                                locale={es}
                                                selected={values.pause_start}
                                                onChange={date => {
                                                    const d = startOfDay(date as Date)
                                                    setFieldValue("pause_start", d)
                                                }}
                                                customInput={<input className="form-control"/>}
                                            />
                                        </div>
                                        <FieldError name="pause_start"/>
                                    </div>
                                    <div className=" ml-2">
                                        <p className="m-0">Finalizar pausa en: </p>
                                        <div className="customDatePickerWidth" style={{width: "220px"}}>
                                            <ReactDatePicker
                                                dateFormat="d 'de' MMMM 'del' yyyy"
                                                minDate={addDays(values.pause_start, 1)}
                                                locale={es}
                                                selected={values.pause_end}
                                                onChange={date => {
                                                    const d = startOfDay(date as Date)
                                                    setFieldValue("pause_end", d)
                                                    setPauseEnd(d)
                                                }}
                                                customInput={<input className="form-control"/>}
                                            />
                                        </div>
                                        <FieldError name="pause_end"/>
                                        <div className="font-weight-bold text-muted mt-0"> * El usuario podrá reservar
                                            de esta fecha en adelante.
                                        </div>
                                    </div>
                                </div>
                                <hr/>

                                {renderPauseResultMessage(values.pause_start)}
                                <div className="text-muted font-weight-bold mt-3"><FontAwesomeIcon
                                    icon={faInfoCircle}/> En caso de cancelar la pausa antes de su final, la
                                    vigencia de la membresía sera actualizada solo por los días de pausa transcurridos.
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Pausar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default PauseMembershipModal;
