import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";

import {TimeSlot} from "../Schedules";
import {Member} from "../Members";
import WaitListTr from "../../components/table/rows/WaitListTr";
import baseApi from "../../apis/baseApi";
import TableHeader from "../../components/table/TableHeader";
import EmptyTr from "../../components/table/rows/EmptyTr";

type WaitListTableProps = {
    timeslot: TimeSlot
}

export interface WaitList {
    id: string
    member: Member
    cancelled: boolean
    activated: boolean
}

const WaitListTable = ({timeslot} : WaitListTableProps) => {
    const [waitList, setWaitList] = useState<WaitList[]>([])

    useEffect(() => {
        baseApi.get(`/rsvp/waitlist/staff/?timeslot=${timeslot.id}`).then((resp) => {
            setWaitList(resp.data)
        })
    }, [timeslot]);

    return (
        <div>
            <p className="text-muted">La reservación se crea automaticamente cuando se libera un espacio minimo 45 minutos antes de la clase.</p>
            <Table>
                <TableHeader headers={["ID", "NOMBRE", "TALLA", "ACCIÓN"]} />
                <tbody>
                    {waitList.length === 0 ? <EmptyTr /> : waitList.map((item) => <WaitListTr key={item.id} waitListItem={item}/>)}
                </tbody>
            </Table>
        </div>
    );
};

export default WaitListTable;
