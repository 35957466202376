import React, {useEffect, useRef, useState} from 'react';
import baseApi from "../../../apis/baseApi";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {formatDate, formatDateTime} from "../../../utils/dateFormatters";
import {Button, Table} from 'react-bootstrap';
import TableFooter from "../../../components/table/TableFooter";
import AddMemberCreditsModal from "./Modals/AddMemberCreditsModal";
import RemoveCreditsModal from "./Modals/RemoveMemberCreditsModal";
import {Member} from "../index";

export interface CreditsTransaction {
    id: string
    amount: number
    created_on: string
    credits: string
    description: string
    transaction_by: string
    expire_on: string
}

interface Props {
    member: Member,
    setMember: (member: Member) => void
}

const MemberCredits = ({member, setMember}: Props) => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [transactions, setTransactions] = useState<CreditsTransaction[]>([]);
    const count = useRef(0);

    useEffect(() => {
        handlePageChange(1)
    }, []);

    const handlePageChange = (page: number) => {
        baseApi.get(`/credits/transactions/?member=${member.id}&page=${page}`).then((resp) => {
            count.current = resp.data.count
            setTransactions(resp.data.results)
        }).catch((err) => alert("Error"))
    }

    return (
        <div>
            <AddMemberCreditsModal show={showCreateModal} onHide={() => setShowCreateModal(false)} member={member}
                                   setMember={setMember}
                                   transactions={transactions} setTransactions={setTransactions}/>
            <RemoveCreditsModal show={showRemoveModal} onHide={() => setShowRemoveModal(false)} setMember={setMember}
                                member={member}
                                transactions={transactions} setTransactions={setTransactions}/>
            <div className="text-right">
                <Button onClick={() => setShowCreateModal(true)} className="my-2" size="sm">
                    + Agregar créditos
                </Button>
                <Button variant="outline-danger" onClick={() => setShowRemoveModal(true)} className="my-2 ml-2"
                        size="sm">
                    - Restar créditos
                </Button>
            </div>
            <Table hover>

                <TableHeader headers={["#", "Fecha", "Cantidad", "Concepto", "Vencimiento", "Creado por"]}/>
                <tbody>
                {transactions.length === 0 ? <EmptyTr/> : transactions.map((transaction) => {
                    return <tr className="text-center" key={transaction.id}>
                        <td className="text-uppercase">{transaction.id.slice(30)}</td>
                        <td>{formatDateTime(transaction.created_on)}</td>
                        <td>{transaction.amount > 0 ? "+" : "" }{transaction.amount}</td>
                        <td>{transaction.description}</td>
                        <td>{transaction.amount > 0 ? formatDate(transaction.expire_on) : ""}</td>
                        <td>{transaction.transaction_by}</td>
                    </tr>
                })}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={transactions.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberCredits;
