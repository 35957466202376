import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Col, Container, Row} from "react-bootstrap";
import {BrowserRouter, Redirect} from "react-router-dom";

import {StoreState} from "../reducers";
import {AuthState, Branch, BranchesState, fetchBranches, getTokensFromStorage, logoutWithError} from "../actions";
import {fetchStudioClasses, StudioClassesState} from "../actions/studioClasses";
import {getStaffUser, StaffUser} from "../actions/staffUser";
import {fetchStudioPlans} from "../actions/studioPlans";
import Sidebar from "./Sidebar";
import Routes from './Routes';
import Login from "../pages/Login";
import TopBar from "./TopBar";

import "../assets/styles/styles.css"
import "../assets/styles/k/primjsbundle.css"
import "../assets/styles/k/stylebundle.css"
import "../assets/styles/k/aside_light.css"
import "../assets/styles/k/brand_light.css"
import "../assets/styles/k/header_light.css"
import "../assets/styles/k/header_menu_light.css"
import "../assets/styles/newStyles.css"
import "../assets/styles/uiconsRegularRounded.css"
import "../assets/styles/loaders.css"

import Onboarding from "./Onboarding";
import {faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import baseApi from "../apis/baseApi";
import {faBell} from "@fortawesome/free-regular-svg-icons";
import {Studio} from "../actions/studio";
import {fetchRegistrationFees, RegistrationFeesState} from "../actions/registrationFees";
import LoginX from "../pages/LoginX";

export interface StudioAdminNotification {
    id: number
    message: number;
}

const App = () => {
    const dispatch = useDispatch()
    const authState = useSelector<StoreState, AuthState>(state => state.auth)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const classesState = useSelector<StoreState, StudioClassesState>(state => state.studioClasses)
    const staffUser = useSelector<StoreState, StaffUser>(state => state.staffUser)
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const registrationFeesState = useSelector<StoreState, RegistrationFeesState>(state => state.registrationFees)
    const [notifications, setNotifications] = useState<StudioAdminNotification[]>([]);
    const [showSidebar, setShowSidebar] = useState(false);
    const [hideAlert, setHideAlert] = useState(false)

    useEffect(() => {
        const userLang = navigator.language || navigator.languages[0];
        // @ts-ignore
        window.__localeId__ = userLang.replace("-", "")
    }, []);



    useEffect(() => {
        dispatch(getTokensFromStorage())
    }, [dispatch])

    useEffect(() => {
        if (currentBranch.id) {
            dispatch(fetchStudioPlans(currentBranch.id))
        }
    }, [currentBranch, dispatch])

    useEffect(() => {
        if (authState.loggedIn) {
            baseApi.get("/sa/notifications/").then(resp => {
                setNotifications(resp.data)
            }).catch()
        }
    }, [authState]);


    if (authState.loading) {
        return <div>Cargando</div>
    }
    if (!authState.loggedIn) {
        if (window.location.pathname === "/xlogin") {
            return <LoginX />
        }
        return <Login/>
    }
    if (branchesState.loading) {
        dispatch(fetchBranches())
        return <div>Cargando datos...</div>
    }
    if (classesState.loading) {
        dispatch(fetchStudioClasses())
        return <div>Cargando datos...</div>
    }
    if (staffUser.loading) {
        dispatch(getStaffUser())
        return <div>Cargando datos...</div>
    }
    if (staffUser.archived) {
        dispatch(logoutWithError("Cuenta inactiva"))
        return <Redirect to={"/login"} />
    }
    if (studio.id && !registrationFeesState.fetched) {
        dispatch(fetchRegistrationFees(studio.id))
    }

    return (
        <div className="wrapper on-welcome" style={{height: "100%"}}>
            <BrowserRouter>
                <Sidebar showSidebar={showSidebar}/>
                <Onboarding/>
                <div id="content" className="pt-2 px-5 h-100">
                    <Container fluid className="h-100">
                        <TopBar showSidebar={showSidebar} setShowSidebar={setShowSidebar}/>
                        {notifications.map(n => <div key={n.id}>
                            <Alert className="d-flex justify-content-between alert-warning">
                                <div>
                                    <i className="mr-3 text-white">
                                        <FontAwesomeIcon icon={faBell}/>
                                    </i>
                                    {n.message}
                                </div>
                                <i style={{cursor: "pointer"}} className="ml-auto text-white" onClick={() => {
                                    setNotifications(notifications.filter(notification => n.id !== notification.id))
                                    baseApi.patch(`/sa/notifications/${n.id}/`, {viewed: true}).then()
                                }}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </i>
                            </Alert>
                        </div>)}
                        <div onClick={() => setHideAlert(true)} hidden={hideAlert}>
                            <Alert className="d-flex justify-content-between d-md-none alert-primary">
                                <div>
                                    <i className="mr-1">
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>
                                    Ingresa desde una tablet o computadora
                                    para una
                                    mejor experiencia.
                                </div>
                                <i className="ml-auto">
                                    <FontAwesomeIcon icon={faTimes}/>
                                </i>
                            </Alert>
                        </div>
                        {/*<div onClick={() => setHideAlert(true)} hidden={hideAlert}>*/}
                        {/*    <Alert className="d-flex justify-content-between alert-primary">*/}
                        {/*        <div>*/}
                        {/*            <i className="mr-1 text-white">*/}
                        {/*                <FontAwesomeIcon icon={faInfoCircle}/>*/}
                        {/*            </i>*/}
                        {/*            Horarios a partir del 3 de abril pueden verse con 1 hora de retraso debido al cambio horario, se actualizarán a la hora correcta el 3 de abril 1:00am*/}
                        {/*        </div>*/}
                        {/*        <i className="ml-auto">*/}
                        {/*            <FontAwesomeIcon icon={faTimes}/>*/}
                        {/*        </i>*/}
                        {/*    </Alert>*/}
                        {/*</div>*/}
                        <Row className="h-100">
                            <Col className="h-100 pt-3">
                                <Routes/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </BrowserRouter>
        </div>
    );
};

export default App;
