import {Dispatch} from "redux";

import {Reservation} from "../pages/Reservations";
import {TimeSlot} from "../pages/Schedules";
import {TimeSlotReservationsTypes} from "./types";
import baseApi from "../apis/baseApi";

export interface TimeslotReservationsState {
    loading: boolean
    reservations: Reservation[]
    timeslot: TimeSlot
}

export interface TimeslotReservationsActions {
    type: TimeSlotReservationsTypes
    payload: any
}

export const fetchReservations = (timeslot: TimeSlot) => {
    return async (dispatch: Dispatch) => {
        dispatch({type: TimeSlotReservationsTypes.FETCHING, payload: null})
        baseApi.get<Reservation[]>(`/rsvp/timeslot/?timeslot=${timeslot.id}`).then((response) => {
            dispatch<TimeslotReservationsActions>({
                type: TimeSlotReservationsTypes.FETCHED,
                payload: {reservations: response.data, timeslot: timeslot}
            })
        })
    }
}

export const addReservation = (reservation: Reservation) => {
    return {
        type: TimeSlotReservationsTypes.ADD_RESERVATION,
        payload: reservation
    }
}

export const updateReservation = (reservation: Reservation) => {
    baseApi.patch(`/rsvp/update/${reservation.id}/`, {assistance: reservation.assistance}).then()
    return {
        type: TimeSlotReservationsTypes.UPDATE_RESERVATION,
        payload: reservation
    }
}