import React, {useEffect} from 'react';
import {useState} from 'react';
import {Alert, Button, Modal, Row, Col} from "react-bootstrap";
import {Formik, Field, Form as FormikForm,} from "formik";
import FormField from "../../../components/form/FormField";
import {useDispatch, useSelector} from "react-redux";

import {StoreState} from "../../../reducers";
import {BranchesState} from "../../../actions";
import Checkbox from "../../../components/form/Checkbox.js";
import FormSelectAlt, {SelectOption} from "../../../components/form/FormSelectAlt";
import {UserWithStaff} from "./index";
import baseApi from "../../../apis/baseApi";
import {getApiErrorMessage} from "../../../utils/apiErrors";
import {CoachesState, fetchCoaches} from "../../../actions/coaches";

type CreateStaffModalProps = {
    show: boolean,
    onHide: Function
    user?: UserWithStaff
    staffUsers: UserWithStaff[]
    setStaffUsers: (staff: UserWithStaff[]) => void
}

const CreateStaffModal = ({show, onHide, user, setStaffUsers, staffUsers}: CreateStaffModalProps) => {
    const dispatch = useDispatch()
    const coachesState = useSelector<StoreState, CoachesState>(state => state.coaches)
    const studioId = useSelector<StoreState, string>(state => state.currentBranch.parent)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)

    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMsg, setAlertMsg] = useState("");

    const renderAlert = () => {
        if (success === null) return;
        return <Alert variant={success ? "success" : "danger"}>{alertMsg}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    useEffect(() => {
        if (coachesState.loading) {
            dispatch(fetchCoaches())
        }
    }, [coachesState, dispatch]);


    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        full_name: user === undefined ? "" : user.full_name,
                        username: user === undefined ? "" : user.username,
                        password: "",
                        phone: "",
                        is_staff_of: branchesState.selectOptions.filter(option => user?.staff.is_staff_of.includes(option.value)),
                        view_all_coaches: user?.staff.view_all_coaches ?? true,
                        coaches_filter: user?.staff.view_all_coaches || !user ? [{
                            value: "ALL",
                            label: "Todos"
                        }] : user!.staff.coaches_filter.map(c => ({value: c, label: coachesState?.coaches.find(x => x.id === c)?.full_name})),
                        permissions: user?.staff.permissions.reduce((map, obj) => {
                            // @ts-ignore
                            map[obj.feature] = obj
                            return map
                        }, {}) || {
                            billing: {view: false, edit: false},
                            branches: {view: true, create: false, edit: false, delete: false},
                            schedules: {view: false, create: false, edit: false, delete: false},
                            classes: {view: true, create: false, edit: false, delete: false},
                            clients: {view: false, create: false, edit: false, delete: false},
                            dashboard: {view: true},
                            memberships: {view: true, create: false, edit: false, delete: false},
                            notifications: {view: false, create: false, edit: false, delete: false},
                            coaches: {view: false, create: false, edit: false, delete: false},
                            personalization: {view: false},
                            products: {create: false, edit: false, delete: false},
                            pos: {view: false, create: false},
                            reports: {view: false},
                            reservations: {view: false, create: false, edit: false, delete: false},
                            rooms: {view: true, create: false, edit: false, delete: false},
                            settings: {view: false, edit: false},
                            staff: {view: false, create: false, edit: false, delete: false},
                        }
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        let viewAllCoaches = values.view_all_coaches
                        let coachesFilter = values.coaches_filter.filter(c => c.value !== "ALL").map(c => c.value)
                        if (coachesFilter.length >= 1) {
                            viewAllCoaches = false
                        } else {
                            viewAllCoaches = true
                            coachesFilter = []
                        }
                        if (user) {
                            baseApi.patch(`/staff/update/${user.id}/`, {
                                ...values,
                                staff: {
                                    studio: studioId,
                                    is_staff_of: values.is_staff_of.map((b: SelectOption) => b.value),
                                    view_all_coaches: viewAllCoaches,
                                    coaches_filter: coachesFilter,
                                    // @ts-ignore
                                    permissions: Object.entries(values.permissions).map(i => ({feature: i[0], ...i[1]}))
                                }
                            }).then((resp) => {
                                setAlertMsg("Se ha actualizado el usuario staff correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        } else {
                            baseApi.post("/staff/", {
                                ...values,
                                staff: {
                                    studio: studioId,
                                    is_staff_of: values.is_staff_of.map((b: SelectOption) => b.value),
                                    view_all_coaches: viewAllCoaches,
                                    coaches_filter: coachesFilter,
                                    // @ts-ignore
                                    permissions: Object.entries(values.permissions).map(i => ({feature: i[0], ...i[1]}))
                                }
                            }).then((resp) => {
                                setStaffUsers([resp.data, ...staffUsers])
                                setAlertMsg("Se ha creado el usuario staff correctamente")
                                setSuccess(true)
                                setSubmitting(false)
                            }).catch((err) => {
                                setAlertMsg(getApiErrorMessage(err))
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }

                    }}>
                    {({isSubmitting, values, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                {user ? "Editar" : "Crear"} Usuario STAFF
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <FormField autoComplete="name" label="- Nombre" name="full_name"/>
                                <FormField autoComplete="email" label="- E-Mail" name="username"/>
                                <FormField autoComplete="phone" label="- Teléfono" name="phone"/>
                                {user ? <React.Fragment/> :
                                    <FormField autoComplete="new-password" type="password" label="- Contraseña"
                                               name="password"/>}
                                <FormSelectAlt
                                    label="Sucursales" placeholder="Seleccionar sucursales"
                                    value={values.is_staff_of}
                                    options={branchesState.selectOptions}
                                    onChange={(options: SelectOption[]) => setFieldValue("is_staff_of", options)}
                                    isMulti={true}/>
                                <hr/>
                                <div className="d-inline-flex">
                                    <h6 className="pr-3">Permisos</h6>
                                    <Button hidden className="p-1" variant="outline-primary" size="sm">Seleccionar
                                        Todos</Button>
                                </div>
                                <Row>

                                    <Col>
                                        <p className="text-primary font-weight-bold">Ver</p>
                                        <Field component={Checkbox} name="permissions.dashboard.view"
                                               label="Dashboard"/>
                                        <Field component={Checkbox} name="permissions.reservations.view"
                                               label="Reservaciones"/>
                                        <Field component={Checkbox} name="permissions.schedules.view"
                                               label="Horarios"/>
                                        <Field component={Checkbox} name="permissions.clients.view" label="Clientes"/>
                                        <Field component={Checkbox} name="permissions.notifications.view"
                                               label="Notificaciones"/>
                                        <Field component={Checkbox} name="permissions.coaches.view"
                                               label="Coaches"/>
                                        <Field component={Checkbox} name="permissions.reports.view" label="Reportes"/>
                                        <Field component={Checkbox} name="permissions.staff.view" label="Staff"/>
                                        <Field component={Checkbox} name="permissions.personalization.view"
                                               label="Personalización"/>
                                        <Field component={Checkbox} name="permissions.pos.view" label="Punto de Venta"/>
                                        <Field component={Checkbox} name="permissions.billing.view" label="Plan"/>
                                        <Field component={Checkbox} name="permissions.settings.view"
                                               label="Preferencias"/>

                                    </Col>
                                    <Col>
                                        <p className="text-primary font-weight-bold">Crear</p>
                                        <Field component={Checkbox} name="permissions.reservations.create"
                                               label="Reservaciones"/>
                                        <Field component={Checkbox} name="permissions.schedules.create"
                                               label="Horarios"/>
                                        <Field component={Checkbox} name="permissions.clients.create" label="Clientes"/>
                                        <Field component={Checkbox} name="permissions.notifications.create"
                                               label="Notificaciones"/>
                                        <Field component={Checkbox} name="permissions.coaches.create"
                                               label="Coaches"/>
                                        <Field component={Checkbox} name="permissions.reports.create" label="Reportes"/>
                                        <Field component={Checkbox} name="permissions.branches.create"
                                               label="Sucursales"/>
                                        <Field component={Checkbox} name="permissions.staff.create" label="Staff"/>
                                        <Field component={Checkbox} name="permissions.classes.create" label="Clases"/>
                                        <Field component={Checkbox} name="permissions.memberships.create"
                                               label="Créditos/Membresías"/>
                                        <Field component={Checkbox} name="permissions.pos.create" label="Ventas"/>
                                        <Field component={Checkbox} name="permissions.rooms.create" label="Salones"/>
                                        <Field component={Checkbox} name="permissions.products.create"
                                               label="Productos/Inventario"/>
                                    </Col>
                                    <Col>
                                        <p className="text-primary font-weight-bold">Editar</p>
                                        <Field component={Checkbox} name="permissions.reservations.edit"
                                               label="Reservaciones"/>
                                        <Field component={Checkbox} name="permissions.schedules.edit"
                                               label="Horarios"/>
                                        <Field component={Checkbox} name="permissions.clients.edit" label="Clientes"/>
                                        <Field component={Checkbox} name="permissions.notifications.edit"
                                               label="Notificaciones"/>
                                        <Field component={Checkbox} name="permissions.coaches.edit"
                                               label="Coaches"/>
                                        <Field component={Checkbox} name="permissions.reports.edit" label="Reportes"/>
                                        <Field component={Checkbox} name="permissions.branches.edit"
                                               label="Sucursales"/>
                                        <Field component={Checkbox} name="permissions.staff.edit" label="Staff"/>
                                        <Field component={Checkbox} name="permissions.classes.edit" label="Clases"/>
                                        <Field component={Checkbox} name="permissions.memberships.edit"
                                               label="Créditos/Membresías"/>
                                        <Field component={Checkbox} name="permissions.rooms.edit" label="Salones"/>
                                        <Field component={Checkbox} name="permissions.billing.edit" label="Plan"/>
                                        <Field component={Checkbox} name="permissions.settings.edit" label="Ajustes"/>
                                        <Field component={Checkbox} name="permissions.products.edit"
                                               label="Productos/Inventario"/>
                                    </Col>
                                    <Col>
                                        <p className="text-primary font-weight-bold">Eliminar / Cancelar</p>
                                        <Field component={Checkbox} name="permissions.reservations.delete"
                                               label="Reservaciones"/>
                                        <Field component={Checkbox} name="permissions.schedules.delete"
                                               label="Horarios"/>
                                        <Field component={Checkbox} name="permissions.clients.delete" label="Clientes"/>
                                        <Field component={Checkbox} name="permissions.notifications.delete"
                                               label="Notificaciones"/>
                                        <Field component={Checkbox} name="permissions.branches.delete"
                                               label="Sucursales"/>
                                        <Field component={Checkbox} name="permissions.staff.delete" label="Staff"/>
                                        <Field component={Checkbox} name="permissions.classes.delete" label="Clases"/>
                                        <Field component={Checkbox} name="permissions.memberships.delete"
                                               label="Créditos/Membresías"/>
                                        <Field component={Checkbox} name="permissions.rooms.delete" label="Salones"/>
                                        <Field component={Checkbox} name="permissions.products.delete"
                                               label="Productos"/>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col>
                                        <div className="offset-lg-1 text-muted font-size-sm">* El usuario solo podrá ver
                                            horarios y reservaciones de estos coaches
                                        </div>
                                        <FormSelectAlt
                                            label="Coaches" placeholder="Seleccionar coaches"
                                            value={values.coaches_filter}
                                            options={[{
                                                value: "ALL",
                                                label: "Todos"
                                            }, ...coachesState.coaches.map(c => ({value: c.id, label: c.full_name}))]}
                                            onChange={(options: SelectOption[]) => {
                                                setFieldValue("coaches_filter", options);
                                            }}
                                            isMulti={true}/>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default CreateStaffModal;
