import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faPause, faSyncAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {parseISO, isBefore, format} from "date-fns";

import TablePill from "../LabelPill";
import {formatDate} from "../../../utils/dateFormatters";
import {MemberPlan} from "../../../pages/Members/MemberDetails/MemberPlans";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {StudioPlan} from "../../../actions/studioPlans";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";

type MemberPlanTrProps = {
    memberPlan: MemberPlan,
    onCancel: () => void,
    onEditExpiration: () => void,
    onPauseClicked: () => void,
}

const MemberPlanTr = ({memberPlan, onCancel, onEditExpiration, onPauseClicked}: MemberPlanTrProps) => {
    const planActivateTT = (props: any) => (<Tooltip id={`logout-tt-${memberPlan.id}`} {...props}>Este plan se activara
        el {format(parseISO(memberPlan.activate_on), "dd/MMM/yyyy")}</Tooltip>)

    const studioId = useSelector<StoreState, string>(state => state.studio.id)

    const getDaysDurationText = (plan: StudioPlan) => {
        let text = `${plan.interval_count} `
        if (plan.interval === "day") {
            text += plan.interval_count === 1 ? "día" : "días"
        } else if (plan.interval === "week") {
            text += plan.interval_count === 1 ? "semana" : "semanas"
        } else if (plan.interval === "month") {
            text += plan.interval_count === 1 ? "mes" : "meses"
        } else if (plan.interval === "year") {
            text += plan.interval_count === 1 ? "año" : "años"
        }
        return <span>{text}</span>
    }

    const getStatusBadge = () => {
        if (memberPlan.cancelled) {
            return <TablePill variant="warning" text="Cancelada"/>
        }
        if (isBefore(parseISO(memberPlan.expire_on), new Date())) {
            return <TablePill text="Vencida" variant="danger"/>
        }
        if (isBefore(new Date(), parseISO(memberPlan.activate_on))) {
            return <OverlayTrigger
                placement="left"
                overlay={planActivateTT}
                delay={{show: 100, hide: 400}}
            >
                <div>
                    <TablePill text={"En espera"} variant="info"/>
                </div>
            </OverlayTrigger>
        }
        const activePause = memberPlan.pause_schedules.find(s => s.status === "active" && isBefore(parseISO(s.pause_start), new Date()) && isBefore(new Date(), parseISO(s.pause_end)))
        if (activePause) {
            return <TablePill text="En Pausa" variant="info"/>
        }
        return <TablePill text="Vigente"/>
    }

    return (
        <tr className="font-size-sm align-middle text-center" key={memberPlan.id}>
            {/*TODO: change to sale id*/}
            <td className="text-center text-uppercase">{memberPlan.id.slice(-6)}</td>
            <td>{formatDate(memberPlan.created_on)}</td>
            <td className="text-left">
                <div>{memberPlan.plan.name} {memberPlan.subscription_id ?
                    <FontAwesomeIcon className="text-success" icon={faSyncAlt}/> : <React.Fragment/>}</div>
                <div
                    className="text-muted font-size-xs">{memberPlan.plan.no_limit_credits ? getDaysDurationText(memberPlan.plan) : `${memberPlan.plan.credits} créditos`}</div>
            </td>
            <td>{memberPlan.plan.price}</td>
            <td>
                {memberPlan.credits_available ? memberPlan.credits_available : "-"}
                {/*{memberPlan.credits_available ? <span className="text-primary ml-1"><FontAwesomeIcon icon={faEdit} size="sm" /></span> : ""}*/}

            </td>
            <td onClick={onEditExpiration}>
                {formatDate(memberPlan.expire_on)}
                <span hidden={memberPlan.cancelled} className="text-primary clickable ml-2"><FontAwesomeIcon icon={faEdit}/></span>
            </td>

            <td>
                {getStatusBadge()}
            </td>
            <td className="px-0 text-center">
                <Button
                    disabled={memberPlan.cancelled || isBefore(parseISO(memberPlan.expire_on), new Date())}
                    onClick={() => {
                        onPauseClicked()
                    }}
                    className="btn btn-icon btn-light btn-xs btn-hover-warning mr-1">
                    <FontAwesomeIcon icon={faPause}/>
                </Button>
                <Button
                    disabled={memberPlan.cancelled || isBefore(parseISO(memberPlan.expire_on), new Date())}
                    onClick={() => {
                        onCancel()
                    }}
                    className="btn btn-icon btn-light btn-xs btn-hover-danger">
                    <FontAwesomeIcon icon={faTimes}/>
                </Button>
            </td>
        </tr>
    );
};

export default MemberPlanTr;
