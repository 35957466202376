import React, {useEffect, useState} from 'react';

import CreateRoomModal from "./CreateRoomModal";
import ToolboxHeader from "../ToolboxHeader";
import {Table} from 'react-bootstrap';
import TableHeader from "../../../components/table/TableHeader";
import TableActionsCell from "../../../components/table/TableActionsCell";
import DeleteModal from "../../../components/modals/DeleteModal";
import baseApi from "../../../apis/baseApi";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {Branch} from "../../../actions";
import SeatMaintenanceMap from "./SeatMaintenanceMap";

export interface Seat {
    id: string;
    label: string;
    row: number;
    col: number;
    is_coach: boolean;
    in_maintenance: boolean;
}

export interface Room {
    id: string;
    seats: Seat[];
    archived: boolean;
    name: string;
    people_limit: number;
    rows: number;
    cols: number;
    branch: string;
    uses_map: boolean;
}

const Rooms = () => {
    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showMaintenance, setShowMaintenance] = useState(false)

    const [rooms, setRooms] = useState<Room[]>([])
    const [selectedRoom, setSelectedRoom] = useState<Room>()

    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)

    useEffect(() => {
        baseApi.get<Room[]>("/rooms/").then((resp) => {
            setRooms(resp.data)
        }).catch(() => alert("Error"))
    }, [])

    const getBranchName = (id: string) => {
        return branches.find(b => b.id === id)?.name ?? ""
    }

    return (
        <div className="p-4">
            <CreateRoomModal show={showCreate} onHide={() => {
                setShowCreate(false)
                setSelectedRoom(undefined)
            }} room={selectedRoom} onSave={(room) => {
                const index = rooms.findIndex(r => r.id === room.id)
                if (index >= 0) {
                    setRooms(rooms.map((e) => e.id === room.id ? room : e))
                } else {
                    setRooms([...rooms, room])
                }
            }}/>
            <DeleteModal url={`/rooms/${selectedRoom?.id}/`} onSuccess={() => {
                setRooms(rooms.filter(r => r.id !== selectedRoom?.id))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedRoom(undefined)
            }}>
                <p>¿Estas seguro que quieres eliminar el salon <strong> {selectedRoom?.name} </strong> ?</p>
            </DeleteModal>
            {/*<SeatMaintenanceMap show={showMaintenance} onHide={() => {*/}
            {/*    setShowMaintenance(false)*/}
            {/*    setSelectedRoom(undefined)*/}
            {/*}} room={selectedRoom!}/>*/}
            <ToolboxHeader title="Salones" action={() => setShowCreate(true)}/>
            <div className="mt-5">
                <Table className="text-center">
                    <TableHeader headers={["Nombre", "Capacidad", "Sucursal", "Mapa", "Acciones"]}/>
                    <tbody>
                    {rooms.length === 0 ? <EmptyTr/> : rooms.map((r) => (
                        <tr key={r.id}>

                            <td>{r.name}</td>
                            <td>{r.people_limit}</td>
                            <td>{getBranchName(r.branch)}</td>
                            <td>{r.uses_map ? "SI" : "NO"}</td>
                            <td><TableActionsCell
                                // onClickDetails={() => {
                                //     setSelectedRoom(r)
                                //     setShowMaintenance(true)
                                // }}
                                onClickDelete={() => {
                                    setSelectedRoom(r)
                                    setShowDelete(true)
                                }}
                                onClickEdit={() => {
                                    setSelectedRoom(r)
                                    setShowCreate(true)
                                }}
                            />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Rooms;
